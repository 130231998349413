<template>
	<div>
		<b-card no-body>
			<b-card title="" header-tag="header">
				<template v-slot:header>
					<product-form-nav link="filter" :productDetails="productDetails" :productId="id"></product-form-nav>
				</template>
				<div style="max-width:800px">
				<form class="form" novalidate="novalidate" id="product_filter">
					<span class="text-danger">Filters are linked to categories in link tab,Please make sure categories are selected.</span>
					<b-card-text class="v-application">
						<b-row class="mb-2">
							<b-col cols="12" class="mb-2" v-for="group in filterGroup" :key="group.id">
								<label>{{ group.label }}</label>
								<treeselect v-model="category[group.id]" :multiple="true" :flat="true" :max-height="200" 
									:default-expand-level="1" :options="group.children" />
							</b-col>
						</b-row>
						<b-row>
							<b-col class="text-right">
								<button class="btn btn-primary" ref="submit_filter">
									<i class="las la-save"></i> Save and Continue
								</button>
							</b-col>
						</b-row>
					</b-card-text>
				</form>
				</div>
			</b-card>
		</b-card>
	</div>
</template>

<script>
	import ProductFormNav from "./components/ProductFormNav.vue";
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

	// FormValidation plugins
	import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
	import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
	import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
	import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
	import KTUtil from "@/assets/js/components/util";

	//api services
	import ProductService from "@/core/services/api/product/product";
	import FilterService from "@/core/services/api/filter";
	export default {
		components: {
			ProductFormNav,
		},
		data() {
			return {
				id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
				form:{},
				filterGroup: [],
				productDetails:{},
				selectedList:[]
			};
		},
		created (){
			this.getProductById();
			this.getCategoryFilterList();
			this.getSelectedFilterByProductId();
		},
		mounted() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: "Dashboard",
					route: "/dashboard",
				},
				{
					title: "Products",
					route: "/products",
				},
				{
					title: "Manage Product : Link",
				},
			]);
			
			const signin_form = KTUtil.getById("product_filter");
			this.fv = formValidation(signin_form, {
				plugins: {
					trigger: new Trigger(),
					submitButton: new SubmitButton(),
					bootstrap: new Bootstrap(),
				},
			});
			this.fv.on("core.form.valid", () => {
				// set spinner to submit button
				const submitButton = this.$refs["submit_filter"];
				submitButton.classList.add("spinner", "spinner-light", "spinner-right","disabled");
				this.updateProduct(function(){
					debugger
					submitButton.classList.remove(
						"spinner",
						"spinner-light",
						"spinner-right",
						"disabled"
					);
				});
			});
				
		},
		methods: {
			tree(e){
				var children = [];
				e.map((child)=>{
					children.push({
						id:child.id,
						label:child.name,
						group:child.filter_group_id,
					})
				})
				return children;
			},
			async getCategoryFilterList() {
				var response = await ProductService.getFilterByProductId(this.id);
				if(response){
					response.map((e)=>{
						this.filterGroup.push({
							id:e.id,
							label:e.name,
							children:e.filter?this.tree(e.filter):[]
						})
					});
				}
				
			},
			async getProductById() {
				var vm = this;
				var response = await ProductService.getProductById(this.id);
				if(response){
					vm.productDetails = response;
				}
			},
			async getSelectedFilterByProductId() {
				var vm = this;
				vm.selectedList = await ProductService.getSelectedFilterByProductId(this.id);
				
			},
			updateProduct(callback) {
				var vm = this;
				var filters = [];
				for(const cat of this.category) {
					if(cat != undefined){
						cat.map((e)=>{
							filters.push(e)
						})
					}
				}
				var form = {
					filters:filters
				}
				ProductService.updateProductFilter(vm.id,form).then(function (response) {
						if(vm.productDetails.varient == 1)
							vm.$router.push({ name: "product-options", param: { id: vm.id } })
						else
							vm.$router.push({ name: "product-form-image-upload", param: { id: vm.id } })
				}).catch(function (error){
					callback();
				});
			},
		},
		computed:{
			category: function(){
				var vm = this;
				var cat = [];
				var selectedList = this.selectedList;
				for(const filter of selectedList) {
					vm.filterGroup.map((e)=>{
						if(cat[e.id] == undefined){
							cat[e.id]=[];
						}
						e.children.map(c => {
							if(c.id == filter.filter_id)
								cat[e.id].push(filter.filter_id);
						});
					})
				}
				return cat;
			}
		}
	};
</script>

<style></style>